import React from "react"
import { graphql } from "gatsby"

import { Stores as Page } from "@components/Stores/Stores"

export const query = graphql`
  query {
    page: sanityStoresPage {
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      heroBanner: _rawHeroBanner(resolveReferences: { maxDepth: 10 })
      _rawLinks(resolveReferences: { maxDepth: 10 })
      _rawConsultationButtonLink(resolveReferences: { maxDepth: 10 })
      _rawContent: _rawContent(resolveReferences: { maxDepth: 10 })
    }
    products: allSanityProductRange {
      edges {
        node {
          title
        }
      }
    }
  }
`

export default ({ data, ...props }) => <Page {...props} {...data} />
