import React from "react"

import { useApp } from "@hooks/useApp"
import { useLanguage } from "@hooks/useLanguage"
import { useShopify } from "@hooks/useShopify"
import { useStores } from "@hooks/useStores"

export const withStoresLocations = Component => ({ name = `StoresLocations`, location, products }) => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()
  const locales = useLanguage(`stores`)
  const { edgeNormaliser } = useShopify()
  const {
    filteredStores: stores,
    filerStoresFlagship,
    handleResetFilters,
    availableCountries,
    availableStates,
    handleHighlight,
    filtered,
    filters,
    handleFilters,
    handleGeoLocation,
    geolocatingLoading,
  } = useStores(location)

  const ranges = edgeNormaliser(products)?.map(({ title }) => title)

  const countries = availableCountries.map(country => ({
    label: country,
    value: country,
  }))

  const allStates = availableStates.map(state => ({
    label: state,
    value: state,
  }))

  const states = allStates?.length
    ? [
        {
          label: locales.allStates,
          value: null,
        },
        ...allStates,
      ]
    : [
        {
          label: locales.allStates,
          value: null,
        },
      ]

  stores.sort((a, b) => (a.city > b.city ? 1 : b.city > a.city ? -1 : 0))

  Component.displayName = name
  return (
    <Component
      countries={countries}
      filtered={filtered}
      filters={filters}
      filerStoresFlagship={filerStoresFlagship}
      handleFilters={handleFilters}
      handleHighlight={handleHighlight}
      handleResetFilters={handleResetFilters}
      handleGeoLocation={handleGeoLocation}
      locales={locales}
      ranges={ranges}
      routes={routes}
      states={states}
      stores={stores}
      geolocatingLoading={geolocatingLoading}
    />
  )
}
