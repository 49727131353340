import React, { ReactElement } from "react"
import { Link } from "gatsby"

import { withStoresLocations } from "./withStoresLocations"
import { RichText } from "../../RichText/RichText"
import { Dropdown } from "../../Styled/Dropdown/Dropdown"
import { H5 } from "../../Styled/Text"
import { PrimaryButton } from "../../Styled/Button"
import { MoonLoader } from "react-spinners"

import {
  Checkboxes,
  CheckboxWrapper,
  Details,
  Filter,
  DropdownWrapper,
  IconNext,
  Empty,
  InfoWrapper,
  Links,
  LocationContent,
  LocationsRow,
  LocationsContainer,
  LocationsColumn,
  StyledFlagship,
  StyledFlex,
  StyledLabel,
  StyledP,
  StyledTextButton,
  Stores,
  Store,
  Wrapper,
} from "../StoresStyles"

interface Props {
  countries: Array<any>
  filters: any
  filtered: any
  filerStoresFlagship: any
  handleFilters: any
  handleResetFilters: any
  handleHighlight: any
  handleGeoLocation: () => void
  geolocatingLoading: boolean
  locales: any
  ranges: Array<any>
  routes: any
  states: any
  stores: Array<any>
}

export const StoresLocations = withStoresLocations(
  ({
    countries,
    filters,
    filtered,
    handleFilters,
    handleResetFilters,
    handleHighlight,
    locales,
    ranges,
    routes,
    states,
    stores,
    handleGeoLocation,
    geolocatingLoading,
    filerStoresFlagship,
  }: Props) => (
    <LocationsContainer width={`lg`}>
      <Wrapper>
        <LocationsRow spacing>
          <LocationsColumn>
            <LocationsRow bottom>
              <LocationsColumn>
                <Filter>
                  <StyledP as={`label`}>{locales.country}</StyledP>
                  <DropdownWrapper>
                    <Dropdown
                      label={locales.selectCountry}
                      options={[
                        {
                          label: locales.allCountries,
                          value: null,
                        },
                        ...countries,
                      ]}
                      value={filters.country}
                      onChange={value => handleFilters(`country`, value)}
                    />
                  </DropdownWrapper>
                </Filter>

                <Filter hiddenLg={`true`}>
                  <StyledP as={`label`}>{locales.state}</StyledP>
                  <DropdownWrapper>
                    <Dropdown
                      disabled={!filters.country}
                      label={locales.selectState}
                      options={states}
                      value={filters.country ? filters.state : null}
                      onChange={value => handleFilters(`state`, value)}
                    />
                  </DropdownWrapper>
                </Filter>
              </LocationsColumn>

              <LocationsColumn>
                <PrimaryButton className="relative" full={`true`} onClick={handleGeoLocation} disabled={geolocatingLoading}>
                  {locales.find}
                  <span className="absolute right-1 top-1 ">
                    <MoonLoader loading={geolocatingLoading} size={16} />
                  </span>
                </PrimaryButton>
              </LocationsColumn>
              <LocationsColumn>
                <PrimaryButton full={`true`} onClick={() => filerStoresFlagship()}>
                  Find flagship stores
                </PrimaryButton>
              </LocationsColumn>
            </LocationsRow>
          </LocationsColumn>
        </LocationsRow>

        <LocationsRow spacing>
          <LocationsColumn>
            <H5>{`${filters.country ? filters.country : locales.allCountries}`}</H5>
          </LocationsColumn>
        </LocationsRow>

        <Stores as={stores.length ? `ul` : null} spacing>
          {stores.length ? (
            stores.map(store => (
              <Store key={store.slug}>
                <LocationContent highlight={handleHighlight(store)}>
                  <Details>
                    <StyledLabel>{store.title}</StyledLabel>
                    <StyledP as={`div`}>{store?.city}</StyledP>
                  </Details>
                  <InfoWrapper>
                    <StyledFlex as={`div`}>
                      {store?.productRanges?.map(item => (
                        <StyledFlagship>{item}</StyledFlagship>
                      ))}
                    </StyledFlex>
                    {store.flagship ? (
                      <StyledTextButton as={`a`} href={`/contact`}>
                        Book now
                      </StyledTextButton>
                    ) : null}
                    <Links>
                      {store.email ? (
                        <StyledTextButton as={`a`} href={`mailto:${store.email}`}>
                          {locales.emailButton}
                        </StyledTextButton>
                      ) : null}
                      <StyledTextButton as={Link} to={`${routes.STORE}/${store.slug}`}>
                        <span>{locales.viewButton}</span> <IconNext />
                      </StyledTextButton>
                    </Links>
                  </InfoWrapper>
                </LocationContent>
              </Store>
            ))
          ) : (
            <Empty>{locales.empty}</Empty>
          )}
        </Stores>

        <LocationsRow end>
          <LocationsColumn end>
            <PrimaryButton disabled={!filtered} full onClick={() => handleResetFilters({ country: null, ranges })}>
              {locales.reset}
            </PrimaryButton>
          </LocationsColumn>
        </LocationsRow>
      </Wrapper>
    </LocationsContainer>
  )
)
