import React, { useCallback, useState } from "react"
import { Link } from "gatsby"
import { Marker, InfoWindow } from "@react-google-maps/api"
import { IconNext, StyledFlagship, StyledFlex, StyledP, StyledTextButton } from "../../StoresStyles"
import { useApp } from "@hooks/useApp"
import { RichText } from "../../../RichText/RichText"

type Props = {
  store: any
  setOpen: typeof useState
  open: boolean
  handleSelect: Function
}

export const StoresMapPin: React.FC<Props> = React.memo(({ store, open, setOpen, handleSelect }) => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()

  const handleClick = useCallback(() => {
    if (open) {
      setOpen(null)
    } else {
      handleSelect(store.location)
      setOpen(store.id)
    }
  }, [handleSelect, open, setOpen, store])

  const icon = `data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 50 50' width="35px" height="35px" fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M25 6.25c-8.062 0-14.583 6.52-14.583 14.583C10.417 31.771 25 43.75 25 43.75s14.584-11.98 14.584-22.917C39.584 12.771 33.063 6.25 25 6.25zm0 20.833a6.25 6.25 0 100-12.5 6.25 6.25 0 000 12.5z' fill='currentColor'/%3E%3C/svg%3E%0A`
  return (
    <>
      <Marker icon={icon} position={{ lat: store?.location.lat, lng: store?.location.lng }} title={store?.title} onClick={handleClick}>
        {open && (
          <InfoWindow onCloseClick={handleClick}>
            <>
              <StyledTextButton as={Link} to={`${routes.STORE}/${store.slug}`}>
                <span>{store.title}</span> <IconNext />
              </StyledTextButton>
              <StyledP as={`div`}>
                <RichText content={store.address} />
              </StyledP>
              <StyledFlex as={`div`}>
                {store?.productRanges?.map((item, i, store) =>
                  i + 1 === store.length ? (
                    <StyledFlagship>{item === "Chosen" ? "Chosen by KYHA" : item} available</StyledFlagship>
                  ) : (
                    <StyledFlagship>{item === "Chosen" ? "Chosen by KYHA" : item} and</StyledFlagship>
                  )
                )}
              </StyledFlex>
            </>
          </InfoWindow>
        )}
      </Marker>
    </>
  )
})
