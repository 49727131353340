import React, { ReactElement } from "react"

import { withStores } from "./withStores"
import { HeroBanner } from "../Sections/HeroBanner/HeroBanner"
import { StoresItem } from "./Item/StoresItem"
import { StoresLocations } from "./Locations/StoresLocations"
import { StoresMap } from "./Map/StoresMap"
import { RichText } from "../RichText/RichText"
import { SeoDescription, StyledContainer, StyledH2 } from "./StoresStyles"

interface Props {
  content: any
  hero: any
  location: any
  products: Array<any>
}

const OptionalSeoDescription = ({ label, as }: { label: string; as: string }): ReactElement =>
  label ? <SeoDescription as={as}>{`${label}`}</SeoDescription> : null

export const Stores = withStores(({ content, hero, location, products }: Props) => (
  <>
    <HeroBanner section={hero} condensed={`true`} gradient={`true`} />
    <StyledContainer>
      <StyledH2>{content.title}</StyledH2>
      <OptionalSeoDescription as={`p`} label={content.description} />
    </StyledContainer>
    <StoresMap location={location} />
    <StoresLocations location={location} products={products} />
  </>
))
