import { useState, useEffect } from "react"
import { graphql, navigate, useStaticQuery } from "gatsby"

import { useApp } from "./useApp"
import { useRoutes } from "./useRoutes"
import { useShopify } from "./useShopify"

const query = graphql`
  query STORES_QUERY {
    stores: allSanityStore {
      edges {
        node {
          id: _id
          _rawAddress(resolveReferences: { maxDepth: 10 })
          _rawLocation(resolveReferences: { maxDepth: 10 })
          _rawSlug(resolveReferences: { maxDepth: 10 })
          _rawProductRanges(resolveReferences: { maxDepth: 10 })
          email
          country
          flagship
          state
          title
          city
          productRanges {
            title
          }
        }
      }
    }
  }
`

export const useStores = location => {
  const {
    config: {
      settings: { params },
    },
  } = useApp()
  const { edgeNormaliser } = useShopify()
  const { getUrlParameter, setUrlParameter } = useRoutes()
  const data = useStaticQuery(query)

  const stores = edgeNormaliser(data?.stores).map(store => ({
    ...store,
    location: { ...store._rawLocation, type: (store._rawLocation || {})._type },
    slug: store._rawSlug.current,
    address: store._rawAddress,
    addressText: store._rawAddress?.[0]?.children?.[0]?.text,
    productRanges: store.productRanges.map(range => range.title),
  }))

  const [filteredStores, setFilteredStores] = useState(stores)
  const [search, setSearch] = useState(getUrlParameter(params.stores)?.toLowerCase() || ``)
  const [filters, setFilters] = useState({
    country: getUrlParameter(params.country),
    range: getUrlParameter(params.range),
    state: getUrlParameter(params.state),
  })
  const [geolocatingLoading, setGeolocatingLoading] = useState(false)

  const handleFilters = (type: string, value) => {
    const newFilters = {
      ...filters,
    }

    if (type === `country`) {
      newFilters[`state`] = null
    }

    newFilters[type] = value
    setFilters(null)
    setSearch(null)
    setFilters(newFilters)
  }

  const handleResetFilters = filters => {
    setFilters(null)
    setSearch(null)
    setFilters(filters)
  }

  const handleGeoLocation = () => {
    const searchRadius = 30 // Kms
    setGeolocatingLoading(true)
    handleFilters("country", null)

    navigator.geolocation.getCurrentPosition(
      position => {
        const userLocation: LatLng = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }

        const filteredStores = stores.filter(store => {
          const distance = getDistance(store._rawLocation?.lat, store._rawLocation?.lng, userLocation.lat, userLocation.lng)
          return searchRadius > Math.floor(distance)
        })

        setFilteredStores(filteredStores)
        setGeolocatingLoading(false)
      },
      err => {
        setGeolocatingLoading(false)
        console.error(`ERROR(${err.code}): ${err.message}`)
      }
    )
  }

  const handleHighlight = store =>
    search &&
    (store.title?.toLowerCase()?.includes(search) ||
      store?.addressText?.toLowerCase()?.includes(search) ||
      store.country?.toLowerCase()?.includes(search))

  useEffect(() => {
    const { country, range, state } = filters
    const filteredStores = stores
      .filter(store => store.country === country || !country)
      .filter(store => store.state === state || !state)
      .filter(store => store.productRanges?.includes(range) || !range)

    // remove /au, /us from url
    let url = location.pathname.split("/").pop() === "stores" ? "/stores" : location.pathname
    if (search) {
      url = setUrlParameter(params.stores, search, url)
    }
    if (country) {
      url = setUrlParameter(params.country, country, url)
    }
    if (state) {
      url = setUrlParameter(params.state, state, url)
    }
    if (range) {
      url = setUrlParameter(params.range, range, url)
    }

    setFilteredStores(filteredStores)
    navigate(url, { replace: true })
  }, [filters])

  const availableCountries = stores
    .map(store => store.country)
    .filter((item, index, array) => array.indexOf(item) === index)
    .sort()

  const availableStates = stores
    .filter(store => store.state && store.country === filters[`country`] && filters[`country`])
    .map(store => store.state)
    .filter((item, index, array) => array?.indexOf(item) === index)
    .sort()

  const filtered = stores.length !== filteredStores.length

  const getDistance = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
    if (lat1 == lat2 && lon1 == lon2) {
      return 0
    } else {
      var radlat1 = (Math.PI * lat1) / 180
      var radlat2 = (Math.PI * lat2) / 180
      var theta = lon1 - lon2
      var radtheta = (Math.PI * theta) / 180
      var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
      if (dist > 1) {
        dist = 1
      }
      dist = Math.acos(dist)
      dist = (dist * 180) / Math.PI
      dist = dist * 60 * 1.1515

      return dist * 1.609344
    }
  }

  const filerStoresFlagship = () => {
    const flagshipStores = stores.filter(store => store.flagship === true)
    setFilteredStores(flagshipStores)
  }

  return {
    availableCountries,
    availableStates,
    filtered,
    filteredStores,
    filters,
    geolocatingLoading,
    filerStoresFlagship,
    handleFilters,
    handleHighlight,
    handleResetFilters,
    handleGeoLocation,
  }
}

export interface LatLng {
  lat: number
  lng: number
}
