import React from "react"

import { useSanity } from "@hooks/useSanity"
import { useSection } from "@hooks/useSection"

export const withStores = Component => ({ name = `Stores`, location, page, products, title, description }) => {
  const { rawContentNormaliser, linkNormaliser, heroNormaliser, textNormaliser } = useSanity()
  const { transformSection } = useSection()

  const content = {
    ...page,
    heroBanner: heroNormaliser(page?.heroBanner),
    content: rawContentNormaliser(page),
    links: page._rawLinks?.map(link => linkNormaliser(link)) || [],
    consultationButton: linkNormaliser(page._rawConsultationButtonLink),
  }
  const hero = transformSection(content.heroBanner)

  Component.displayName = name
  return <Component content={content} hero={hero} location={location} products={products} />
}
