import React, { useCallback, useMemo, useState } from "react"
import { useJsApiLoader } from "@react-google-maps/api"
import { useApp } from "@hooks/useApp"
import { useStores } from "@hooks/useStores"

export const withStoresMap = Component => ({ name = `StoresMap`, location }) => {
  const {
    config: {
      services: { googleMaps },
    },
  } = useApp()

  const [map, setMap] = React.useState(null)
  const { filteredStores: stores } = useStores(location)
  const [open, setOpen] = useState(null)

  const config = useMemo(
    () => ({
      id: googleMaps?.mapId,
      mapContainerStyle: { width: `100%`, height: `100%` },
      options: {
        disableDefaultUI: true,
        mapId: googleMaps?.mapId,
        minZoom: 2,
        zoomControl: true,
        styles: [
          {
            stylers: [
              {
                saturation: -100,
              },
            ],
          },
        ],
      },
    }),
    [googleMaps]
  )

  const handleLoad = useCallback(
    map => {
      const bounds = new window.google.maps.LatLngBounds()

      stores?.map(store => bounds.extend(new window.google.maps.LatLng(store?.location?.lat, store?.location?.lng)))
      map.fitBounds(bounds)
      map.addListener(`click`, () => setOpen(false))
      setMap(map)
    },
    [stores, setMap, setOpen]
  )

  const handleSelect = useCallback(
    bounds => {
      map.setCenter(bounds)
      map.setZoom(3)
      setMap(map)
    },
    [map, setMap]
  )

  const handleUnload = useCallback(() => setMap(null), [setMap])

  const { isLoaded } = useJsApiLoader({
    id: name,
    googleMapsApiKey: googleMaps?.apiKey,
    mapIds: [googleMaps?.mapId],
    libraries: ["places"],
  })

  Component.displayName = name
  return (
    <Component
      handleSelect={handleSelect}
      stores={stores}
      isLoaded={isLoaded}
      config={config}
      onLoad={handleLoad}
      onUnmount={handleUnload}
      setOpen={setOpen}
      open={open}
    />
  )
}
